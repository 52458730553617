<template>
  <div>
    <h3 class="pageHeader mb-3" style="color: white">Enter OTP</h3>
    <p class="font-weight-light text-lg-h6 text-xl-h5">
      {{
        authMethod === 'email'
          ? $t('message.aSixDigitCodeHasBeenSentViaEmailTo')
          : $t('message.aSixDigitCodeHasBeenSentViaSMSTo')
      }}
      {{ authMethod === 'email' ? me.email : phoneNumber }}
    </p>
    <p class="font-weight-light text-lg-h6 text-xl-h5 pink2--text">
      {{ $t('label.resendCodeIn') }} <span id="timer"></span>
    </p>

    <v-otp-input
      class="otp-input mt-5 mb-12"
      dark
      length="6"
      type="number"
      v-model="otp"
      @finish="verifyOTPAction"
    >
    </v-otp-input>
    <div class="text-center mt-12 pt-12">
      <v-btn class="btn1 mt-10" @click="resendOTP" :disabled="isResendDisabled">
        Resend OTP
      </v-btn>
    </div>
  </div>
</template>

<script>
  import {
    AUTH_REQUEST_OTP,
    AUTH_VERIFY_OTP,
    AUTH_INITIAL_REQUEST_OTP_STATE,
    AUTH_INITIAL_VERIFY_OTP_STATE,
    AUTH_INITIAL_ME_STATE
  } from '@/store/auth.module';
  import { i18nHelper } from '@/utils';
  import { bus } from '@/main.js';
  import { PROFILE } from '@/api';
  import { OTP_TYPE } from '@/constants/enums/otp-type.enum.js';

  export default {
    name: 'DeleteAccountModalContent',
    props: ['me'],
    data: () => ({
      isResendDisabled: true,
      otp: null
    }),
    computed: {
      authMethod() {
        if (this.me.mobile_dial_code && this.me.mobile) return 'mobile';
        return 'email';
      },
      phoneNumber() {
        let trimCount = {
          '+65': 4,
          '+60': 5
        };
        let hiddenCount = {
          '+65': 'XXXX',
          '+60': 'XXXXX'
        };

        if (this.me?.mobile) {
          return `${this.me.mobile_dial_code}-${
            hiddenCount[this.me.mobile_dial_code]
          } ${this.me.mobile.substr(trimCount[this.me.mobile_dial_code])}`;
        } else {
          return '+65-XXXXXXX';
        }
      },
      requestOTPComplete() {
        return this.$store.state.auth.requestOTP.complete;
      },
      verifyOTPComplete() {
        return this.$store.state.auth.verifyOTP.complete;
      }
    },
    watch: {
      requestOTPComplete() {
        let response = this.$store.state.auth.requestOTP;

        if (response.complete) {
          this.requestOTPCompleteAction(response);
        }
      },
      verifyOTPComplete() {
        let response = this.$store.state.auth.verifyOTP;

        if (response.complete) {
          this.verifyOTPCompleteAction(response);
        }
      }
    },
    methods: {
      requestOTP() {
        const data = {
          auth_method: this.authMethod,
          mobileDialCode: this.me.mobile_dial_code,
          mobile: this.me.mobile,
          email: this.me.email,
          otpType: OTP_TYPE.PERMANENT_DELETE_ACCOUNT
        };
        this.$store.dispatch(AUTH_REQUEST_OTP, { data });
      },
      resendOTP() {
        this.requestOTP();
      },
      requestOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$nextTick(function () {
            this.setResendOTPTimer();
            this.isResendDisabled = true;
          });
        }

        this.initialRequestOTP();
      },
      initialRequestOTP() {
        this.$store.dispatch(AUTH_INITIAL_REQUEST_OTP_STATE);
      },
      verifyOTPAction() {
        const data = {
          auth_method: this.authMethod,
          mobileDialCode: this.me.mobile_dial_code,
          mobile: this.me.mobile,
          email: this.me.email,
          otpType: 'permanent_delete_account',
          code: this.otp
        };

        this.verifyOTP(data);
      },
      verifyOTP(data) {
        this.$store.dispatch(AUTH_VERIFY_OTP, { data });
      },
      async verifyOTPCompleteAction(response) {
        if (response.code == 200) {
          this.$startLoading();
          try {
            await PROFILE.permanentDeleteAccount();
            this.$nextTick(() => {
              this.$store.dispatch(AUTH_INITIAL_ME_STATE);
            });
            this.$router.push('/');
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.verifyOTP');
          let description = response.message;
          let buttons = [
            {
              color: 'title',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialVerifyOTP();
      },
      initialVerifyOTP() {
        this.$store.dispatch(AUTH_INITIAL_VERIFY_OTP_STATE);
      },
      setResendOTPTimer() {
        let vm = this;
        document.getElementById('timer').innerHTML = 1 + ':' + 0;

        startTimer();

        function startTimer() {
          var presentTime = document.getElementById('timer').innerHTML;
          var timeArray = presentTime.split(/[:]+/);
          var m = timeArray[0];
          var s = checkSecond(timeArray[1] - 1);
          if (s == 59) {
            m = m - 1;
          }
          if (m < 0) {
            return;
          }

          document.getElementById('timer').innerHTML = m + ':' + s;

          if (m == '0' && s == '00') {
            vm.isResendDisabled = false;
          }

          setTimeout(startTimer, 1000);
        }

        function checkSecond(sec) {
          if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
          } // add zero in front of numbers < 10
          if (sec < 0) {
            sec = '59';
          }
          return sec;
        }
      }
    },
    // mounted() {
    //   this.requestOTP();
    // },
    created() {
      bus.$on('requestOTP', () => {
        this.requestOTP();
      });
    }
  };
</script>

<style scoped lang="scss">
  .otp-input {
    ::v-deep .v-input__slot {
      color: #ff2ff2;
      background: transparent !important;
      min-height: 80px;
    }
  }
</style>
