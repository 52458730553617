<template>
  <div class="container wrapper">
    <h1 class="mt-12 mb-5 pageHeader">Delete Account</h1>
    <div class="pb-12 mb-12 contentContainer">
      Deleting account is permanent, you will not be able to retrieve the
      account.
    </div>
    <div class="text-center">
      <v-btn class="btn1 my-12" @click="openDeleteAccModal()"> Delete </v-btn>
    </div>
    <ModalBase id="deleteAccountModal" persistent closeOnAlert>
      <DeleteAccountModalContent :me="me" />
    </ModalBase>
  </div>
</template>

<script>
  import ModalBase from '@/components/base/ModalBase';
  import DeleteAccountModalContent from '@/components/profile/DeleteAccountModalContent';
  import { bus } from '@/main.js';

  export default {
    name: 'DeleteAccount',
    components: {
      ModalBase,
      DeleteAccountModalContent
    },
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      }
    },
    methods: {
      openDeleteAccModal() {
        this.$openModal('deleteAccountModal');

        this.$nextTick(function () {
          bus.$emit('requestOTP', true);
        });
      }
    }
  };
</script>

<style scoped>
  .contentContainer {
    height: 200px;
  }
</style>
